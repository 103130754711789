import { createContext } from 'react';
import { DefaultSelectPropsType, SelectSettingsType, SelectContextType } from '../components/CustomizableSelect/types';
import { OpenState, HorizontalAlign, VerticalPosition, EventListenerKeys } from '../constants';
import ActionHandler from 'src/helpers/actions';

export const defaultSelectProps: DefaultSelectPropsType = {
  options: [],
  selected: [],
  wrapperAttrs: {},
  searchInputAttrs: { placeholder: "Search" },
  selectAttrs: {},
  selectElement: null,
  multiple: false,
  placeholder: "Select an option",
  clearable: false,
  showSelectedOptionImage: false,
  showArrow: true,
  searchable: false,
  noOptionsText: "No options found",
  noResultsText: "No results found",
  sortOptions: false,
  showSelection: true,
  multipleSelectionTagStyle: "tag-summary",
  icon: null,
  useCustomIcons: false,
  dropdownPreferredWidth: 100,
  dropdownMaxHeight: 350
};

export const defaultSelectSettings: SelectSettingsType = {
  ...defaultSelectProps,
  init: false,
  uniqueHash: "",
  wrapperElement: null,
  selectHandleElement: null,
  dropdownElement: null,
  dropdownClassList: ["cs-options-wrap"],
  dropdownStyle: {
    width: 0,
    height: 0,
    top: 0,
    left: 0
  },
  dropdownScrollElement: null,
  usesExistingSelectElement: false,
  openState: OpenState.CLOSED,
  isEmpty: true,
  focusedOption: null,
  optionTabIndex: 0,
  optionSearchConfig: [],
  searchInputWrapElement: null,
  isSearching: false,
  searchInputValue: "",
  searchMatchCount: 0,
  isNavigatingWithKeyboard: false,
  selectedOptionImageSrc: null,
  dropdownRepositionData: {},
  updatedFromSelectChange: false,
  preventOnChangeEvent: false,
  dropdownPosition: {
    vertical: VerticalPosition.TOP,
    horizontal: HorizontalAlign.LEFT,
    lastCenterOffsetTop: 0
  },
  events: { ...EventListenerKeys },
  jumpToOptionCharacters: "",
  Actions: new ActionHandler()
};

const defaultContextValue: SelectContextType = {
  settings: defaultSelectSettings,
  updateSettings: () => {},
  showDropdown: () => {},
  hideDropdown: () => {},
  toggleDropdown: () => {},
  arrowNavigateOptions: () => {},
  getVisibleOptions: () => [],
  getSelectedOptions: () => [],
  adjustDropdownPosition: () => {},
  resetSearch: () => {},
  showSearchInputWrapShadow: () => {},
  preventScrollOnArrowPress: () => {},
  selectOption: () => {},
  removeSelectedOption: () => {},
  updateSelectedValues: () => {},
  onSelectKeyDown: () => {},
  IS_OPENED: false,
  IS_CLOSING: false,
  IS_CLOSED: true
};

export const SelectSettingsContext = createContext<SelectContextType>(defaultContextValue);
