import { CustomizableSelectInit } from "@padillaco/customizable-select";
import "../scss/_base.scss";
import "../scss/_theme.scss";
import "../scss/_select.scss";
import "../scss/_options-mario-characters.scss";
import "../scss/_options-profile-menu.scss";

CustomizableSelectInit(".cs-select-init").watch();

document.querySelectorAll(".switch-option").forEach((button) => {
  button.addEventListener("click", (event) => {
    const { index, key } = event.currentTarget.dataset;

    Array
      .from(document.getElementsByClassName(`example-${index}-h4`))
      .forEach((element) => {
        element.style.display = "none";
      });

    document.getElementById(`example-${index}-${key}`).style.display = "block";

    event.currentTarget.parentNode
      .querySelectorAll(".switch-option")
      .forEach((b) => {
        b.classList.remove("-selected");
      });

    button.classList.add("-selected");

    event.currentTarget
      .closest(".edit-example-wrap")
      .querySelector(".edit-example").href = button.value;
  });
});
