import React, { useContext, useEffect, useRef, useState } from "react";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";

type ImageState = {
  isVisible: boolean;
  animated: boolean;
  src: string | null;
};

export default function SelectedOptionImages(): React.JSX.Element | null {
  const { settings } = useContext(SelectSettingsContext);

  const {
    icon,
    selected,
    showSelectedOptionImage,
    selectedOptionImageSrc
  } = settings;

  const [changeImagesTimeout, setChangeImagesTimeout] = useState<number | undefined>();
  
  const [iconIsVisible, setIconIsVisible] = useState<boolean>(
    !!icon && (!showSelectedOptionImage || !selected.length || selected.length === 1 && selected[0] === "")
  );
  
  const [previousImageState, setPreviousImageState] = useState<ImageState>({
    isVisible: false,
    animated: false,
    src: null
  });

  const [currentImageState, setCurrentImageState] = useState<ImageState>({
    isVisible: false,
    animated: false,
    src: null
  });

  useEffect(() => {
    clearTimeout(changeImagesTimeout);

    const { src: currentImageSrc } = currentImageState;

    if (selectedOptionImageSrc) {
      setCurrentImageState({
        isVisible: false,
        animated: false,
        src: selectedOptionImageSrc
      });

      if (currentImageSrc) {
        setPreviousImageState({
          isVisible: true,
          animated: false,
          src: currentImageSrc
        });

        setChangeImagesTimeout(
          window.setTimeout(() => {
            setPreviousImageState((previousImageState) => ({
              ...previousImageState,
              isVisible: false,
              animated: true
            }));

            setCurrentImageState((currentImageState) => ({
              ...currentImageState,
              isVisible: true,
              animated: true
            }));
          }, 0)
        );
      }
      else {
        setPreviousImageState({
          isVisible: false,
          animated: false,
          src: null
        });

        setChangeImagesTimeout(
          window.setTimeout(() => {
            setIconIsVisible(false);

            setCurrentImageState((currentImageState) => ({
              ...currentImageState,
              isVisible: true,
              animated: true
            }));
          }, 0)
        );
      }
    }
    else {
      setCurrentImageState({
        isVisible: false,
        animated: false,
        src: null
      });

      if (currentImageSrc) {
        setPreviousImageState({
          isVisible: true,
          animated: false,
          src: currentImageSrc
        });

        setChangeImagesTimeout(
          window.setTimeout(() => {
            setPreviousImageState((previousImageState) => ({
              ...previousImageState,
              isVisible: false,
              animated: true
            }));

            setIconIsVisible(true);
          }, 0)
        );
      }
      else {
        setPreviousImageState({
          isVisible: false,
          animated: false,
          src: null
        });
      }
    }
  }, [selectedOptionImageSrc]);
  
  const iconClassNames = ["cs-icon-wrap"];

  const {
    isVisible: prevImageIsVisible,
    animated: prevImageAnimated,
    src: prevImageSrc
  } = previousImageState;

  const {
    isVisible: currentImageIsVisible,
    animated: currentImageAnimated,
    src: currentImageSrc
  } = currentImageState;

  const prevImageClassNames: string[] = [];
  const currentImageClassNames: string[] = [];

  if (showSelectedOptionImage) {
    iconClassNames.push(iconIsVisible ? "cs-image-in" : "cs-image-out");
    prevImageClassNames.push(prevImageIsVisible ? "cs-image-in" : "cs-image-out");

    if (prevImageAnimated) {
      prevImageClassNames.push("cs-image-animated");
    }

    currentImageClassNames.push(currentImageIsVisible ? "cs-image-in" : "cs-image-out");

    if (currentImageAnimated) {
      currentImageClassNames.push("cs-image-animated");
    }
  }

  const SelectIcon = () => {
    let iconComponent: React.ReactElement | null = null;

    if (React.isValidElement(icon)) {
      iconComponent = icon;
    }
    else if (typeof icon === "function") {
      const IconFc = icon as React.FC;
      iconComponent = <IconFc/>;
    }

    if (iconComponent) {
      const classes = iconClassNames.join(" ");

      return (
        <div className={classes}>
          {iconComponent}
        </div>
      );
    }

    return null;
  }

  if (!icon && !currentImageSrc) {
    return null;
  }

  return (
    <div className={"cs-selected-option-images"}>
      <SelectIcon/>
      {showSelectedOptionImage && (
        <>
          {prevImageSrc && <img src={prevImageSrc} className={prevImageClassNames.join(" ")} alt="prev"/>}
          {currentImageSrc && <img src={currentImageSrc} className={currentImageClassNames.join(" ")} alt="current"/>}
        </>
      )}
    </div>
  );
}