import React, { useContext } from "react";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";
import IconXMark from "../icons/IconXMark";

export default function SelectedOptions(): React.JSX.Element {
  const {
    settings,
    adjustDropdownPosition,
    removeSelectedOption
  } = useContext(SelectSettingsContext);
  
  const {
    options,
    selected,
    multiple,
    multipleSelectionTagStyle,
    selectHandleElement
  } = settings;

  const maybeRemoveSelectedOption = (event: React.MouseEvent | React.KeyboardEvent) => {
    const { type } = event;

    if (type === "keydown") {
      const { key } = event as React.KeyboardEvent;
      
      if (key !== "Enter" && key !== " ") {
        return;
      }
    }

    event.preventDefault();

    const { value } = event.currentTarget as HTMLButtonElement;
    removeSelectedOption(value);

    if (multiple) {
      setTimeout(adjustDropdownPosition, 0);
    }

    selectHandleElement?.focus();
  };
  
  const selectedOptions = options
    .filter(({ label, value }) => selected.includes(value) && label.length > 0);

  const classes = ["cs-selected-options"];

  if (multiple && multipleSelectionTagStyle === "tag-summary") {
    classes.push("-tag-summary");
  }
  
  return (
    <div className={classes.join(" ")}>
      {selectedOptions.map(({ label, value }, index) => (
        <div key={`selected-${index}`} className="cs-selected-option" data-value={value}>
          <div className="cs-selected-option-text" title={label} dangerouslySetInnerHTML={{ __html: label }}/>
          {multiple && multipleSelectionTagStyle === "removable-tags" && (
            <button
              type="button"
              className="cs-clear"
              onClick={maybeRemoveSelectedOption}
              onKeyDown={maybeRemoveSelectedOption}
              value={value}
              tabIndex={0}
            >
              <IconXMark/>
            </button>
          )}
        </div>
      ))}
      {multiple && multipleSelectionTagStyle === "tag-summary" && selectedOptions.length && (
        <span className="cs-selected-options-count">
          <span>{selectedOptions.length}</span>
        </span>
      )}
    </div>
  );
}