import React, { useContext, useEffect, useRef } from "react";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";
import IconMagnifyingGlass from "../icons/IconMagnifyingGlass";
import IconXMark from "../icons/IconXMark";

const REGEX_SAFE_WORD_REGEX = /(\.|\^|\$|\*|\+|\?|\(|\)|\[|\{|\\|\|)/g;
const tempDiv = document.createElement("div");

export default function SearchInput(): React.JSX.Element {
  const {
    settings,
    updateSettings,
    hideDropdown,
    resetSearch,
    showSearchInputWrapShadow,
    adjustDropdownPosition,
    arrowNavigateOptions,
    IS_OPENED
  } = useContext(SelectSettingsContext);

  const {
    options,
    optionSearchConfig,
    searchInputAttrs,
    isSearching,
    searchInputValue
  } = settings;

  const searchInputWrapRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    searchInputAttrs.className = `${(searchInputAttrs.className || "")} cs-search-input`.trim();
    
    updateSettings((settings) => ({
      ...settings,
      searchInputWrapElement: searchInputWrapRef?.current
    }));
  }, []);
  
  const search = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const keywords = value.trim();

    if (keywords.length) {
      updateSettings((settings) => ({
        ...settings,
        searchInputValue: value
      }));
    }
    else {
      resetSearch();
      return;
    }

    const keywordListCandidates = keywords.replace(/\s{2,}/g, " ").split(" ");
    const keywordList: string[] = [];
    const keywordListRegexSafe: string[] = [];

    for (const keyword of keywordListCandidates) {
      if (!keywordList.includes(keyword)) {
        keywordList.push(keyword);
        
        keywordListRegexSafe.push(
          // regex safe keyword
          keyword.replace(REGEX_SAFE_WORD_REGEX, '\\$1')
        );
      }
    }

    const combinedKeywordListRegexSafe = keywordListRegexSafe.join('|');
    const combinedKeywordRegex = new RegExp(`(${combinedKeywordListRegexSafe})`, 'gi');

    let matches: number[] = [];

    options.forEach(({ html }, index) => {
      const { searchableText } = optionSearchConfig[index];

      if (!searchableText?.length) {
        return;
      }

      let isMatch = true;

      for (const keyword of keywordList) {
        if (searchableText.toLowerCase().indexOf(keyword.toLowerCase()) === -1) {
          isMatch = false;
          break;
        }
      }

      if (isMatch) {
        const searchMatchHighlightedHtml = highlightKeywordsInHtml(html as string, combinedKeywordRegex);

        optionSearchConfig[index] = {
          ...optionSearchConfig[index],
          isSearchMatch: true,
          searchMatchHighlightedHtml
        };

        matches.push(index);
      }
      else {
        delete optionSearchConfig[index].isSearchMatch;
        delete optionSearchConfig[index].searchMatchHighlightedHtml;
      }
    });

    if (!matches.length) {
      showSearchInputWrapShadow(false);
    }

    updateSettings((settings) => ({
      ...settings,
      optionSearchConfig,
      isSearching: true,
      searchMatchCount: matches.length
    }));
  }

  const highlightKeywordsInHtml = (html: string, combinedKeywordRegex: RegExp) => {
    tempDiv.innerHTML = html;
    
    const searchableElement = tempDiv.querySelector(".-searchable") || tempDiv;
    let searchableHtml = searchableElement.innerHTML;
    const htmlTags = searchableHtml.match(/<\/?[^<>]+>/g);
    const htmlParts: string[] = [];
    
    if (htmlTags) {
      htmlTags.forEach((tag) => {
        const tagIndex = searchableHtml.indexOf(tag);
        let htmlBeforeTag = searchableHtml.substring(0, tagIndex);

        if (htmlBeforeTag.length) {
          if (htmlBeforeTag.trim().length) {
            htmlBeforeTag = htmlBeforeTag
              .replace(combinedKeywordRegex, '<strong class="-highlight">$1</strong>')
              .replace(/(\/strong>)(\s+)(<strong)/g, "$1<span>$2</span>$3");
          }

          htmlParts.push(htmlBeforeTag);
        }

        htmlParts.push(tag);
        searchableHtml = searchableHtml.substring(tagIndex + tag.length);
      });
    }

    if (searchableHtml.length) {
      if (searchableHtml.trim().length) {
        searchableHtml = searchableHtml
          .replace(combinedKeywordRegex, '<strong class="-highlight">$1</strong>')
          .replace(/(\/strong>)(\s+)(<strong)/g, "$1<span>$2</span>$3");
      }

      htmlParts.push(searchableHtml);
    }
    
    searchableElement.innerHTML = htmlParts.join(' ');

    return tempDiv.innerHTML;
  }

  const escapeHideDropdown = (event: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>) => {
    if (event.key === "Escape") {
      hideDropdown();
    }
  };

  const onInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    escapeHideDropdown(event);
    arrowNavigateOptions(event);
  };

  const onSearchClear = () => {
    resetSearch();

    if (searchInputRef?.current) {
      (searchInputRef.current as HTMLInputElement).focus();
    }
  };

  useEffect(() => {
    if (!searchInputValue.length) {
      adjustDropdownPosition();
    }
  }, [searchInputValue]);

  return (
    <div
      ref={searchInputWrapRef}
      className={`cs-search-input-wrap ${isSearching ? "-not-empty" : ""}`}
    >
      <input
        type="text"
        spellCheck={false}
        {...searchInputAttrs}
        ref={searchInputRef}
        value={searchInputValue}
        onInput={(event) => search(event)}
        onKeyDown={onInputKeydown}
        tabIndex={IS_OPENED ? 0 : -1}
      />
      <button
        type="button"
        className="cs-search-clear cs-clear"
        onClick={onSearchClear}
        onKeyDown={escapeHideDropdown}
        tabIndex={IS_OPENED && isSearching ? 0 : -1}
      >
        <IconXMark/>
      </button>
      <div className="cs-search-glass">
        <IconMagnifyingGlass/>
      </div>
    </div>
  );
}