import React, { useContext, useEffect, useRef } from "react";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";

type SelectElementProps = {
  onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
};

export default function SelectElement({ onSelectChange }: SelectElementProps): React.JSX.Element {
  const { settings, updateSettings } = useContext(SelectSettingsContext);

  const {
    options,
    selectAttrs,
    multiple,
    selected
  } = settings;

  const selectRef = useRef(null);

  useEffect(() => {
    updateSettings((settings) => ({
      ...settings,
      selectElement: selectRef?.current
    }));
  }, []);

  return (
    <select
      {...selectAttrs}
      ref={selectRef}
      multiple={multiple}
      value={multiple ? selected : selected[0]}
      onChange={onSelectChange}
      tabIndex={-1}
    >
      {options.map(({ label, value }, index) => (
        <option key={`select-option-${index}`} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}