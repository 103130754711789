import React, { useContext } from "react";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";
import IconXMark from '../icons/IconXMark';

export default function ResetButton(): React.JSX.Element {
  const {
    settings,
    updateSettings,
    updateSelectedValues
  } = useContext(SelectSettingsContext);

  const {
    isEmpty,
    selectHandleElement
  } = settings;

  function clearSelectedOptions(event: React.MouseEvent | React.KeyboardEvent) {
    const { type } = event;

    if (type === "keydown") {
      const { key } = event as React.KeyboardEvent;
      
      if (key !== "Enter" && key !== " ") {
        return;
      }
    }

    event.preventDefault();
    
    updateSettings((settings) => ({
      ...settings,
      selectedOptionImageSrc: null
    }));
    
    updateSelectedValues([""]);

    selectHandleElement?.focus();
  }

  return (
    <button
      className={"cs-reset"}
      onClick={clearSelectedOptions}
      onKeyDown={clearSelectedOptions}
      tabIndex={isEmpty ? -1 : 0}
    >
      <IconXMark/>
    </button>
  );
}