export const OpenState = {
  OPENED: "OPENED",
  CLOSING: "CLOSING",
  CLOSED: "CLOSED"
} as const;

export const VerticalPosition = {
  TOP: "-position-top",
  BOTTOM: "-position-bottom",
  CENTER: "-position-center"
} as const;

export const HorizontalAlign = {
  LEFT: "-align-left",
  RIGHT: "-align-right"
} as const;

export const EventListenerKeys = {
  changeCs: "change.cs",
  clickShowSelectOptions: "click.showSelectOptions",
  csDropdownReposition: "csDropdownReposition",
  mousedownOutsideDropdown: "mousedown.outsideDropdown",
  resizeDropdown: "resize.dropdown",
  wheelHideDropdown: "wheel.hideDropdown",
  scrollAdjustDropdownPosition: "scroll.adjustDropdownPosition"
};

export const Keys: Record<string, string> = {
  ARROW_DOWN: "ArrowDown",
  ARROW_UP: "ArrowUp",
  TAB: "Tab",
  ESCAPE: "Escape",
  ENTER: "Enter",
  SPACE: " "
} as const;