import React from "react";

export default function ReusableIcons(): React.JSX.Element {
  return (
    <svg id="cs-icons" style={{ display: "none" }}>
      <defs>
        <symbol id="cs-checkmark">
          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
        </symbol>
        <symbol id="cs-xmark">
          <path d="M393.47,9.38c12.5-12.5,32.8-12.5,45.3,0s12.5,32.8,0,45.3l-169.4,169.3,169.3,169.4c12.5,12.5,12.5,32.8,0,45.3s-32.8,12.5-45.3,0l-169.3-169.4L54.67,438.58c-12.5,12.5-32.8,12.5-45.3,0s-12.5-32.8,0-45.3l169.4-169.3L9.48,54.57C-3.02,42.07-3.02,21.88,9.48,9.38c12.5-12.5,32.8-12.5,45.3,0l169.3,169.3L393.47,9.38Z"/>
        </symbol>
        <symbol id="cs-magnifying-glass">
          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
        </symbol>
        <symbol id="cs-chevron-down">
          <path d="M201.475,246.675c12.5,12.5,32.8,12.5,45.3,0L438.775,54.675c12.5-12.5,12.5-32.8,0-45.3s-32.8-12.5-45.3,0l-169.4,169.4L54.675,9.475C42.175-3.025,21.875-3.025,9.375,9.475s-12.5,32.8,0,45.3l192,192,.1-.1Z"/>
        </symbol>
      </defs>
    </svg>
  );
}