type ActionDataType = {
  callback: () => void;
  persist: boolean;
};

export default class ActionHandler {
  private actions: Record<string, Record<string, ActionDataType>> = {};

  add(
    action: string,
    name: string,
    callback: () => void,
    persist: boolean = false
  ) {
    if (!this.actions[action]) {
      this.actions[action] = {};
    }
    
    this.actions[action][name] = { callback, persist };
  }

  remove(action: string, name: string) {
    if (this.actions[action]) {
      delete this.actions[action][name];

      if (!Object.keys(this.actions[action]).length) {
        delete this.actions[action];
      }
    }
  }

  do(action: string) {
    if (this.actions[action]) {
      Object.entries(this.actions[action]).forEach(([name, { callback, persist }]) => {
        callback();

        if (!persist) {
          this.remove(action, name);
        }
      });
    }
  }
}