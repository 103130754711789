import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import {
  OptionType,
  OptionSearchConfigType,
  SelectPropsType,
  SelectSettingsType,
  SelectContextType
} from './types';
import {
  generateClassList,
  generateRandomHash,
  toggleClassName,
  addEventListener,
  removeEventListener,
  arrayWithout,
  arrayAddUnique,
  hasEventListener,
  objectsAreEqual,
  ObjectEntries,
  getCSSTransformMatrix
} from '../../helpers';
import Options from './Options';
import SelectElement from './SelectElement';
import SelectedOptions from './SelectedOptions';
import { defaultSelectSettings, SelectSettingsContext } from '../../contexts/SelectSettingsContext';
import IconChevronDown from '../icons/IconChevronDown';
import ResetButton from './ResetButton';
import SelectedOptionImages from './SelectedOptionImages';
import ReusableIcons from '../icons/ReusableIcons';
import { defaultSelectProps } from '../../contexts/SelectSettingsContext';
import { OpenState, HorizontalAlign, VerticalPosition, Keys } from '../../constants';
import ActionHandler from 'src/helpers/actions';

const nativeSelectValueSetter = Object.getOwnPropertyDescriptor(
  HTMLSelectElement.prototype,
  "value"
);

export default function CustomizableSelect(props: SelectPropsType) {
  const [settings, updateSettings] = useState<SelectSettingsType>({
    ...defaultSelectSettings,
    ...props
  });

  const {
    init,
    uniqueHash,
    options,
    optionSearchConfig,
    selected,
    wrapperElement,
    wrapperAttrs,
    selectElement,
    selectAttrs,
    usesExistingSelectElement,
    multiple,
    placeholder,
    clearable,
    showSelection,
    showArrow,
    searchable,
    sortOptions,
    icon,
    useCustomIcons,
    isNavigatingWithKeyboard,
    openState,
    searchInputWrapElement,
    isSearching,
    searchMatchCount,
    focusedOption,
    dropdownElement,
    dropdownScrollElement,
    selectHandleElement,
    dropdownPreferredWidth,
    dropdownMaxHeight,
    dropdownStyle,
    dropdownClassList,
    dropdownPosition,
    showSelectedOptionImage,
    dropdownRepositionObserver,
    dropdownRepositionData,
    selectLabelElement,
    updatedFromSelectChange,
    preventOnChangeEvent,
    events,
    jumpToOptionCharacters,
    jumpToOptionTimeout,
    Actions
  } = settings;

  const wrapperRef = useRef(null);
  const selectHandleRef = useRef(null);

  const IS_OPENED = openState === OpenState.OPENED;
  const IS_CLOSING = openState === OpenState.CLOSING;
  const IS_CLOSED = openState === OpenState.CLOSED;

  /**
   * Initializes the component's settings, and sets the initial select value.
   * 
   * @returns {void}
   */
  useEffect(() => {
    /**
     * If the user has provided a custom icon, we need to add it to the DOM.
     * If the user has not provided a custom icon, and there is no element with the id "cs-icons", we need to add the reusable icons to the DOM.
     */
    if (!useCustomIcons && !document.getElementById("cs-icons")) {
      document.body.insertAdjacentHTML("beforeend", renderToString(<ReusableIcons/>));
    }

    /**
     * Create an array of options with the empty option at the beginning.
     * If the user has provided a custom placeholder, use that instead of the default one.
     */
    let adjustedOptions: OptionType[] = [...options];

    const emptyOption = adjustedOptions.find((option) => option.value === "");
    const hasEmptyOption = typeof emptyOption !== "undefined";

    let adjustedPlaceholder = placeholder;

    if (placeholder === defaultSelectProps.placeholder && hasEmptyOption && emptyOption.label.length) {
      adjustedPlaceholder = emptyOption.label;
    }

    /**
     * If the user has provided a custom sort function, use it to sort the options.
     */
    if (sortOptions) {
      adjustedOptions = adjustedOptions.sort((a, b) => (
        a.label === b.label ? 0 : a.label > b.label ? 1 : -1
      ));
    }

    /**
     * If the user has provided a custom clearable option, add an empty option to the beginning of the array.
     */
    if (clearable && !hasEmptyOption) {
      adjustedOptions = [
        { label: "", value: "", html: "" },
        ...adjustedOptions
      ];
    }

    /**
     * Create an array of search config objects.
     * Each object contains the searchable text for the corresponding option.
     */
    const tempDiv = document.createElement("div");
    const optionSearchConfig: OptionSearchConfigType[] = [];

    adjustedOptions.forEach((data, index) => {
      let { html: Html } = data;
      let htmlAsString: string = "";

      /**
       * If the user has provided a custom HTML element for the option, render it to a string.
       */
      if (typeof Html === "function") {
        htmlAsString = renderToString(<Html/>);
      }
      else if (Html instanceof Array || React.isValidElement(Html)) {
        htmlAsString = renderToString(Html);
      }
      else if (typeof Html === "string") {
        htmlAsString = Html;
      }
      
      /**
       * Set the innerHTML of the temp div to the rendered HTML string.
       * If the user has provided a custom selected image src, get the src from the temp div.
       */
      tempDiv.innerHTML = htmlAsString;

      adjustedOptions[index].html = htmlAsString;

      if (showSelectedOptionImage) {
        adjustedOptions[index].selectedImageSrc = (
          tempDiv.querySelector("[data-option-image]") as HTMLElement
        )?.dataset?.selectedImageSrc || (
          tempDiv.querySelector("img.cs-option-image") as HTMLImageElement
        )?.src;
      }
      
      /**
       * If the user has provided a custom searchable text config, add it to the array.
       */
      if (searchable) {
        const searchableElement = tempDiv.querySelector(".-searchable") || tempDiv;

        optionSearchConfig.push({
          searchableText: searchableElement.textContent || ""
        });
      }
    });

    /**
     * Remove the temp div from the DOM.
     */
    tempDiv.remove();

    /**
     * Create an array of option values.
     */
    const adjustedOptionValues = adjustedOptions.map((option) => option.value);

    /**
     * Get the selected values from the user's selected prop.
     * If the user has not provided a selected prop, use the first option in the array.
     */
    let adjustedSelectedValues = (selected || []).filter((value) => (
      adjustedOptionValues.includes(value)
    ));

    if (!multiple && !clearable && !adjustedSelectedValues.length && adjustedOptionValues.length) {
      adjustedSelectedValues = [adjustedOptionValues[0]];
    }

    const isEmpty = !adjustedSelectedValues.length;

    /**
     * Set the selected option image src to the src of the first selected option.
     */
    let selectedOptionImageSrc: string | null | undefined = null;

    if (!multiple && showSelectedOptionImage && !isEmpty) {
      const selectedOption = adjustedOptions.find(({ value }) => adjustedSelectedValues[0] === value);

      if (selectedOption) {
        selectedOptionImageSrc = selectedOption.selectedImageSrc;
      }
    }

    /**
     * Create a class list for the wrapper element.
     */
    let wrapperClassList = generateClassList([
      [multiple, "-multiple", "-single"],
      [searchable, "-searchable"],
      [clearable, "-clearable"],
      [showSelection, "-show-selection"],
      [showArrow, "-arrow"],
      [!!icon || showSelectedOptionImage, "-show-option-images"]
    ]);

    wrapperClassList = ["cs-select", ...wrapperClassList];

    if (wrapperAttrs.className) {
      wrapperClassList.push(wrapperAttrs.className);
    }
    
    wrapperAttrs.className = wrapperClassList.join(" ");

    /**
     * Create a unique hash for the component.
     */
    const uniqueHash = generateRandomHash();

    if (!wrapperAttrs.id) {
      wrapperAttrs.id = `cs-${uniqueHash}`;
    }

    /**
     * Create an object of event names with the unique hash appended to each name.
     */
    const adjustedEvents = { ...events };

    ObjectEntries(adjustedEvents).forEach(([key, value]) => {
      adjustedEvents[key] = `${value}-${uniqueHash}`;
    });

    /**
     * Create a mutation observer for the dropdown reposition event.
     */
    const dropdownRepositionObserver = new MutationObserver(() => {
      document.dispatchEvent(
        new CustomEvent(adjustedEvents.csDropdownReposition, { bubbles: true })
      );
    });

    /**
     * Update the component's state with the new settings.
     */
    updateSettings((settings) => ({
      ...settings,
      ...props,
      init: true,
      uniqueHash,
      options: adjustedOptions,
      optionSearchConfig,
      placeholder: adjustedPlaceholder,
      usesExistingSelectElement: !!selectElement,
      isEmpty,
      wrapperElement: wrapperRef?.current,
      wrapperAttrs,
      preventScrollOnArrowPress,
      selectedOptionImageSrc,
      dropdownRepositionObserver,
      events: adjustedEvents,
      Actions: new ActionHandler()
    }));

    /**
     * Update the select element's value.
     */
    updateSelectedValues(adjustedSelectedValues, false, true);
  }, []);

  /**
   * Handles the change event for the select element.
   * 
   * @param {React.ChangeEvent<HTMLSelectElement>} event The change event.
   */
  const onSelectChange = useCallback((event: React.ChangeEvent<HTMLSelectElement> | Event | CustomEvent | SyntheticEvent<HTMLSelectElement>) => {
    let eventDetail;
    
    // Check if the event is a custom event.
    if (Object.hasOwn(event, "nativeEvent")) {
      const { nativeEvent } = event as SyntheticEvent<HTMLSelectElement>;
      // Extract the detail property from the native event.
      eventDetail = (nativeEvent as CustomEvent).detail;
    }
    
    // If the event is not a custom event, check if it is a native event. We have
    // to check if the event is a native event or a custom event because React's
    // SyntheticEvent does not have a detail property.
    if (!eventDetail && event instanceof CustomEvent) {
      // Extract the detail property from the native event.
      eventDetail = event.detail;
    }

    // If the event is a custom event or a native event with a detail property,
    // check if the detail property is an object with a customizableSelect property.
    if (eventDetail instanceof Object && eventDetail.customizableSelect) {
      // If the event is a custom event or a native event with a detail property,
      // we don't need to do anything else.
      return;
    }

    // If the event is not a custom event or a native event with a detail property,
    // assume that it is a native event and extract the selected values from the
    // options property of the event target.
    const { options } = event.target as HTMLSelectElement;

    const selected = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    // Call the updateSelectedValues function with the selected values and a boolean
    // indicating that the event was triggered by a change to the select element.
    updateSelectedValues(selected, true);
  }, []);

  const updateSelectedValues = (
    selected: string[],
    updatedFromSelectChange: boolean = false,
    preventOnChangeEvent: boolean = false
  ) => {
    updateSettings((settings) => ({
      ...settings,
      selected,
      updatedFromSelectChange,
      preventOnChangeEvent
    }));
  };

  /**
   * Sets up the component once it has been initialized.
   * Attaches the event listener for select element change events,
   * sets the tabIndex of the select element to -1, and inserts it
   * at the beginning of the wrapper element.
   * Finds a <label> element associated with the select element,
   * either by ID or by traversing the DOM tree.
   * Attaches an event listener to handle clicks which will show the dropdown.
   * Updates the component's settings to store the current select handle and label elements.
   */
  useEffect(() => {
    // Check if the component has been initialized. If not, exit early.
    if (!init) {
      return;
    }

    // If using an existing <select> element, attach an event listener for change events,
    // set its tabIndex to -1 to prevent it from being focused, and insert it at the beginning
    // of the wrapper element.
    if (usesExistingSelectElement && selectElement) {
      addEventListener(selectElement, events.changeCs, onSelectChange);
      selectElement.tabIndex = -1;
      wrapperElement?.insertBefore(selectElement, wrapperElement.firstChild);
    }

    // Determine the ID of the select element, either from the element itself or its attributes.
    const selectId = selectElement?.id || selectAttrs.id;
    let selectLabel: HTMLLabelElement | null | undefined = null;

    // If there's an ID, attempt to find a <label> element associated with that ID.
    if (selectId) {
      selectLabel = document.querySelector(`label[for="${selectId}"]`) as HTMLLabelElement;
    }
    
    // If no <label> was found using the ID, attempt to find a <label> by moving up the DOM tree.
    if (!selectLabel) {
      selectLabel = wrapperElement?.closest("label");
    }

    // If a <label> is found, attach an event listener to handle clicks which will show the dropdown.
    if (selectLabel) {
      addEventListener(selectLabel, events.clickShowSelectOptions, onSelectLabelClick);
    }

    // Update the component's settings to store the current select handle and label elements.
    updateSettings((settings) => ({
      ...settings,
      selectHandleElement: selectHandleRef?.current,
      selectLabelElement: selectLabel
    }));
  }, [init]);

  /**
   * Handles updating the value of the original <select> element (if one exists)
   * and dispatching a change event when the component's selected values change.
   * This is necessary because the component uses a fake select element
   * to display the selected options and the actual select element is hidden.
   * The change event is used to notify the outside world that the component's
   * value has changed.
   */
  useEffect(() => {
    // If the component hasn't been initialized, or if the selected values
    // were updated from a change event on the original select element, or
    // if preventOnChangeEvent is set to true, exit early.
    if (!init || updatedFromSelectChange && usesExistingSelectElement || preventOnChangeEvent) {
      return;
    }

    // If the component is using an existing <select> element, set its value
    // to the current selected values. If the component is not multiple, and
    // the selected values are an array, set the value to the first element
    // of the array.
    if (usesExistingSelectElement) {
      let valueToSet: string | string[] = [...selected];

      if (!multiple && Array.isArray(selected)) {
        valueToSet = selected[0];
      }

      // Use the native value setter to set the value of the <select> element.
      // This is necessary because we're working with a fake select element,
      // and the native select element is hidden.
      nativeSelectValueSetter?.set?.call(selectElement, valueToSet);
    }

    // Dispatch a change event on the original <select> element to notify
    // the outside world that the component's value has changed.
    selectElement?.dispatchEvent(
      new CustomEvent("change", {
        bubbles: true,
        detail: {
          customizableSelect: true
        }
      })
    );

    // Adjust the position of the dropdown menu.
    adjustDropdownPosition();
  }, [selected]);

  /**
   * Updates the component's state and DOM attributes based on the current
   * state of the component.
   */
  useEffect(() => {
    if (!init) {
      return;
    }

    /**
     * Determine if the component is empty by checking if the selected values
     * contain any values with length (i.e. not empty strings).
     */
    const isEmpty = selected.filter((value) => value.length).length === 0;

    /**
     * Toggle the "-empty" class on the wrapper element based on whether the
     * component is empty.
     */
    wrapperAttrs.className = toggleClassName(
      "-empty",
      isEmpty,
      wrapperAttrs?.className
    );

    /**
     * Toggle the "-open" class on the wrapper element based on whether the
     * dropdown menu is open.
     */
    wrapperAttrs.className = toggleClassName(
      "-open",
      IS_OPENED,
      wrapperAttrs?.className
    );

    /**
     * Toggle the "-navigate-with-keyboard" class on the wrapper element based
     * on whether the user is navigating the component with the keyboard.
     */
    wrapperAttrs.className = toggleClassName(
      "-navigate-with-keyboard",
      isNavigatingWithKeyboard,
      wrapperAttrs?.className
    );

    updateSettings((settings) => ({
      ...settings,
      wrapperAttrs,
      isEmpty,
      isNavigatingWithKeyboard
    }));
  }, [selected, IS_OPENED, isNavigatingWithKeyboard]);

  /**
   * Prevents the window from scrolling when the user presses the up or down
   * arrow keys while the dropdown menu is open.
   *
   * This is important because the default behavior of the browser is to scroll
   * the window when the user presses the up or down arrow keys. This is not
   * what we want when the dropdown menu is open, because the user is trying to
   * navigate the options in the dropdown menu.
   *
   * This function is called on every key down event when the dropdown menu is
   * open. It checks if the key pressed is the up or down arrow key, and if so,
   * prevents the default behavior of the browser by calling
   * `event.preventDefault()`.
   *
   * @param {React.KeyboardEvent} event The key down event.
   */
  const preventScrollOnArrowPress = (event: React.KeyboardEvent) => {
    const { key } = event;
    const visibleOptionCount = isSearching ? searchMatchCount : options.length;

    // If the dropdown menu is open and the user presses the up or down arrow key,
    // prevent the default behavior of the browser from happening.
    if (IS_OPENED && visibleOptionCount > 0 && (key === Keys.ARROW_DOWN || key === Keys.ARROW_UP)) {
      event.preventDefault();
    }
  };

  /**
   * Handles key down events on the component. This is called when the user
   * presses a key while the component is focused.
   *
   * @param {React.KeyboardEvent} event The key down event.
   */
  const onSelectKeyDown = (event: React.KeyboardEvent) => {
    /**
     * Prevents the window from scrolling when the user presses the up or down
     * arrow keys while the dropdown menu is open.
     */
    preventScrollOnArrowPress(event);

    const { key } = event;
    const element = event.target as HTMLElement;

    /**
     * If the key pressed is one of the navigation keys (up arrow, down arrow,
     * tab, or escape), prevent the default behavior of the browser from
     * happening.
     */
    if ([Keys.ARROW_DOWN, Keys.ARROW_UP, Keys.TAB, Keys.ESCAPE].includes(key)) {
      if (![Keys.TAB, Keys.ESCAPE].includes(key)) {
        event.preventDefault();
      }

      /**
       * If the dropdown menu is not open and the user presses one of the
       * navigation keys, show the dropdown menu.
       */
      if (!IS_OPENED) {
        if ([Keys.ARROW_DOWN, Keys.ARROW_UP].includes(key)) {
          showDropdown(false);
        }
        else {
          return;
        }
      }

      /**
       * If the dropdown menu is open and the user presses one of the
       * navigation keys, navigate to the previous or next option.
       */
      arrowNavigateOptions(event);
    }
    else {
      /**
       * If the key pressed is not a navigation key, check if the element that
       * was focused is the clear button or the reset button. If so, exit early.
       */
      if (element.classList.contains("cs-clear") || element.classList.contains("cs-reset")) {
        return;
      }

      /**
       * If the key pressed is the enter key or the space key, toggle the
       * dropdown menu.
       */
      if (key === Keys.ENTER || (key === Keys.SPACE && !jumpToOptionCharacters.length)) {
        if (element === selectHandleElement) {
          toggleDropdown();
        }
      }
      /**
       * If the key pressed is a single character key (like 'a' or '1'), try
       * to jump to the first option that starts with that character.
       */
      else if (key.length === 1 && dropdownScrollElement) {
        event.preventDefault();

        showDropdown();

        let characters = `${jumpToOptionCharacters}${key}`;
        const visibleOptions = getVisibleOptions();
        
        for (let i = 0; i < visibleOptions.length; i++) {
          const option = visibleOptions[i];

          /**
           * If the option's label starts with the characters that the user has
           * typed, jump to that option.
           */
          if (option.label.toLowerCase().startsWith(characters.toLowerCase())) {
            const optionElements: Element[] = Array.from(
              dropdownScrollElement.children
            );

            let direction = "down";

            /**
             * If the user has previously focused an option, and the option we
             * want to jump to is above the currently focused option, jump up.
             */
            if (focusedOption && optionElements.indexOf(focusedOption) > i) {
              direction = "up";
            }

            const jumpToOptionCallback = () => jumpToOption(optionElements[i], direction, true);

            if (!IS_OPENED) {
              /**
               * If the dropdown menu is not open, add the jumpToOptionCallback
               * to the onOpen actions. This way, when the dropdown menu is
               * opened, the jumpToOptionCallback will be called.
               */
              Actions?.add("onOpen", "jumpToOption", jumpToOptionCallback);
            }
            else {
              /**
               * If the dropdown menu is already open, call the
               * jumpToOptionCallback immediately.
               */
              jumpToOptionCallback();
            }

            break;
          }
        }

        /**
         * Clear the jumpToOptionCharacters timeout and set a new one. This
         * ensures that the user can quickly type out a string to jump to an
         * option.
         */
        clearTimeout(jumpToOptionTimeout);

        const timeout = setTimeout(() => {
          updateSettings((settings) => ({
            ...settings,
            jumpToOptionCharacters: "",
            jumpToOptionTimeout: undefined
          }));
        }, 1000);

        updateSettings((settings) => ({
          ...settings,
          jumpToOptionCharacters: characters,
          jumpToOptionTimeout: timeout
        }));
      }
    }
  };

  useEffect(() => {
    adjustDropdownPosition();
  }, [searchMatchCount]);
  
  const adjustDropdownPosition = useCallback((onOpen: boolean = false, onClose: boolean = false) => {
    // Return early if required elements are not available
    if (!wrapperElement || !dropdownScrollElement) {
      return;
    }

    // Get dimensions and position of the wrapper element
    const {
      width: wrapperWidth,
      height: wrapperHeight,
      top: wrapperTop,
      left: wrapperLeft
    } = wrapperElement.getBoundingClientRect();

    // Initialize width based on preferred or wrapper width
    let width = Math.max(dropdownPreferredWidth, wrapperWidth);
    let searchInputWrapHeight = 0;

    // Calculate search input wrap height if searchable
    if (searchable && searchInputWrapElement) {
      searchInputWrapHeight = searchInputWrapElement.getBoundingClientRect().height;
    }

    // Calculate dropdown height based on max height and content
    let height = Math.min(dropdownMaxHeight, dropdownScrollElement.scrollHeight + searchInputWrapHeight);

    // Define constants for layout and gap size
    const dropdownMaxHeight75Percent = dropdownMaxHeight * 0.75;
    const gapSize = 4;
    const doubleGapSize = 2 * gapSize;
    const spaceTop = wrapperTop - doubleGapSize;
    const spaceBottom = window.innerHeight - (wrapperTop + wrapperHeight + doubleGapSize);

    // Clone existing style and class list
    let style = { ...dropdownStyle };
    let classList = [...dropdownClassList];

    // Deconstruct current dropdown position settings
    let {
      vertical: dropdownVerticalPosition,
      horizontal: dropdownHorizontalAlign,
      lastCenterOffsetTop
    } = dropdownPosition;

    let offsetTop;
    const isClosingState = onClose || IS_CLOSING;

    if (isClosingState) {
      // Calculate offset for closing dropdown
      const currentDropdownHeight = parseFloat(dropdownStyle.height as string || height.toString());

      switch (dropdownVerticalPosition) {
        case VerticalPosition.TOP:
          offsetTop = wrapperTop - gapSize - currentDropdownHeight;
          break;
        case VerticalPosition.BOTTOM:
          offsetTop = wrapperTop + wrapperHeight + gapSize;
          break;
        case VerticalPosition.CENTER:
          offsetTop = wrapperTop - lastCenterOffsetTop;
          break;
      }

      style.top = `${offsetTop}px`;
    } else {
      // Determine dropdown vertical position and offset based on available space
      if (spaceTop > dropdownMaxHeight75Percent || spaceBottom > dropdownMaxHeight75Percent) {
        if (spaceBottom < dropdownMaxHeight75Percent && spaceTop > spaceBottom) {
          dropdownVerticalPosition = VerticalPosition.TOP;
          height = Math.min(height, spaceTop);
          offsetTop = wrapperTop - gapSize - height;
        } else {
          dropdownVerticalPosition = VerticalPosition.BOTTOM;
          height = Math.min(height, spaceBottom);
          offsetTop = wrapperTop + wrapperHeight + gapSize;
        }
      } else {
        dropdownVerticalPosition = VerticalPosition.CENTER;
        height = Math.min(height, window.innerHeight - doubleGapSize);

        offsetTop = Math.max(
          wrapperTop - ((height - wrapperHeight) / 2),
          gapSize
        );

        const pixelsBeyondBottom = offsetTop + height - (window.innerHeight - gapSize);

        if (pixelsBeyondBottom > 0) {
          offsetTop -= pixelsBeyondBottom;
        }

        lastCenterOffsetTop = wrapperTop - offsetTop;
      }

      // Update class list for vertical position
      classList = arrayWithout(classList, [
        VerticalPosition.TOP,
        VerticalPosition.BOTTOM,
        VerticalPosition.CENTER
      ]);

      classList.push(dropdownVerticalPosition);

      // Update style with calculated height and top offset
      style = {
        ...style,
        height: `${height}px`,
        top: `${offsetTop}px`
      };
    }

    // Set height to zero if dropdown is closed
    if (!onOpen && IS_CLOSED) {
      style.height = 0;
    }

    // Calculate available space on left and right
    const spaceLeft = (wrapperLeft + wrapperWidth) - gapSize;
    const spaceRight = window.innerWidth - wrapperLeft - gapSize;
    
    let offsetLeft;
    const maintainHorizontalAlignRight = isClosingState && dropdownHorizontalAlign === HorizontalAlign.RIGHT;

    // Determine dropdown horizontal alignment and offset
    if (maintainHorizontalAlignRight || spaceRight < dropdownPreferredWidth && spaceLeft > spaceRight) {
      dropdownHorizontalAlign = HorizontalAlign.RIGHT;
      width = Math.min(dropdownPreferredWidth, spaceLeft);
      offsetLeft = (wrapperLeft + wrapperWidth) - width;
    } else {
      dropdownHorizontalAlign = HorizontalAlign.LEFT;
      width = Math.min(dropdownPreferredWidth, spaceRight);
      offsetLeft = wrapperLeft;
    }

    // Adjust width to fit within window bounds
    width = Math.min(
      Math.max(width, wrapperWidth),
      window.innerWidth - doubleGapSize
    );

    // Adjust left offset to fit within window bounds
    if (offsetLeft < gapSize) {
      offsetLeft = gapSize;
    } else if (offsetLeft + width > window.innerWidth - gapSize) {
      offsetLeft = window.innerWidth - gapSize - width;
    }

    // Update style with calculated width and left offset
    style = {
      ...style,
      width: `${width}px`,
      left: `${offsetLeft}px`
    };
    
    // Update class list for horizontal alignment
    classList = arrayWithout(classList, [
      HorizontalAlign.LEFT,
      HorizontalAlign.RIGHT
    ]);

    classList.push(dropdownHorizontalAlign);

    // Update settings if style has changed
    if (!objectsAreEqual(style, dropdownStyle)) {
      updateSettings((settings) => ({
        ...settings,
        dropdownClassList: classList,
        dropdownStyle: style,
        dropdownPosition: {
          vertical: dropdownVerticalPosition,
          horizontal: dropdownHorizontalAlign,
          lastCenterOffsetTop
        }
      }));
    }
  }, [
    openState,
    wrapperElement,
    dropdownScrollElement,
    searchInputWrapElement,
    dropdownClassList,
    dropdownStyle,
    dropdownPosition
  ]);

  const resetSearch = useCallback(() => {
    updateSettings((settings) => ({
      ...settings,
      isSearching: false,
      searchInputValue: "",
      searchMatchCount: 0
    }));
  }, []);

  /**
   * Resets the dropdown to its closed state. This function is called when the
   * dropdown is closed, either by the user clicking outside of it, or by the
   * user triggering the onClose action.
   *
   * The function first removes the document mousedown event listener, which
   * was added when the dropdown was opened. This ensures that the dropdown will
   * no longer receive any events after it is closed.
   *
   * Then, the function removes the event listeners for the window resize,
   * wheel, and scroll events, which were added when the dropdown was opened.
   * These event listeners were used to reposition the dropdown when the window
   * was resized, or when the user scrolled the dropdown.
   *
   * The function also disconnects the MutationObserver that was used to observe
   * changes to the dropdown's DOM tree.
   *
   * Next, the function resets the dropdown's height to 0, which hides the
   * dropdown from view. The function also resets the dropdown's scrollTop to 0,
   * which ensures that the dropdown will scroll to the top when it is opened
   * again.
   *
   * Finally, the function calls the resetSearch function, which resets the
   * search input to its initial state. The function then updates the settings
   * object with the new state, which includes setting the openState to
   * OpenState.CLOSED, and setting the focusedOption to null.
   */
  const resetDropdown = useCallback(() => {
    // Remove the event listener for the document mousedown event so that we
    // don't receive any more events after the dropdown is closed.
    removeEventListener(document, events.mousedownOutsideDropdown);

    // Remove the event listeners for the window resize, wheel, and scroll events
    // so that we don't receive any more events after the dropdown is closed.
    removeEventListener(window, events.resizeDropdown);
    removeEventListener(window, events.wheelHideDropdown);
    removeEventListener(window, events.scrollAdjustDropdownPosition);

    // Disconnect the MutationObserver that was used to observe changes to the
    // dropdown's DOM tree so that we don't receive any more events after the
    // dropdown is closed.
    if (dropdownRepositionObserver) {
      dropdownRepositionObserver.disconnect();
    }

    // Reset the dropdown's height to 0, which hides the dropdown from view.
    const style = { ...dropdownStyle, height: 0 };

    // Reset the dropdown's scrollTop to 0, which ensures that the dropdown will
    // scroll to the top when it is opened again.
    if (dropdownScrollElement) {
      dropdownScrollElement.scrollTop = 0;
    }

    // Reset the search input to its initial state.
    resetSearch();

    // Update the settings object with the new state.
    updateSettings((settings) => ({
      ...settings,
      dropdownStyle: style,
      openState: OpenState.CLOSED,
      focusedOption: null
    }));
  }, [dropdownStyle, dropdownScrollElement, resetSearch]);

  /**
   * Hides the dropdown by removing the event listener for the document mousedown
   * event, adding the resetDropdown action to the onClose action queue, setting
   * a short timeout to trigger the onClose action, and updating the settings
   * object with the new state.
   */
  const hideDropdown = useCallback(() => {
    // Remove the event listener for the document mousedown event so that we don't
    // receive any more events after the dropdown is closed.
    removeEventListener(document, events.mousedownOutsideDropdown);

    // Add the resetDropdown action to the onClose action queue. This action will
    // be triggered when the onClose action is called.
    Actions?.add("onClose", "resetDropdown", resetDropdown);

    // Set a short timeout to trigger the onClose action. This gives the dropdown
    // a chance to close before the resetDropdown action is called.
    const onCloseActionTimeout = setTimeout(() => Actions?.do("onClose"), 110);

    // Update the settings object with the new state. Set the optionTabIndex to -1
    // to indicate that no options are currently focused. Set the onCloseActionTimeout
    // to the timeout ID so that we can clear it later. Set the openState to
    // OpenState.CLOSING to indicate that the dropdown is closing. Set the focusedOption
    // to null to indicate that no option is currently focused.
    updateSettings((settings) => ({
      ...settings,
      optionTabIndex: -1,
      onCloseActionTimeout,
      openState: OpenState.CLOSING,
      focusedOption: null,
      isNavigatingWithKeyboard: false
    }));
  }, [resetDropdown]);

  /**
   * Adds or removes a CSS class from the search input wrap element.
   * This class adds a box shadow to the search input wrap element.
   *
   * @param show - If true, the class is added. If false, the class is removed.
   */
  const showSearchInputWrapShadow = (show: boolean = true): void => {
    if (show) {
      // Add the class to add a box shadow to the search input wrap element.
      searchInputWrapElement?.classList.add("-shadow");
    }
    else {
      // Remove the class to remove the box shadow from the search input wrap element.
      searchInputWrapElement?.classList.remove("-shadow");
    }
  };

  /**
   * Listens for clicks outside of the options dropdown and hides the dropdown
   * when such a click is detected.
   *
   * This function is called when the user clicks anywhere outside of the options
   * dropdown while the dropdown is visible. It checks if the click target is
   * the select label element or one of its children. If it is, the function
   * does nothing and returns early. Otherwise, the function removes the event
   * listener and hides the dropdown.
   *
   * @param {MouseEvent | Event} event The click event.
   */
  const onClickOutsideOptions = useCallback((event: MouseEvent | Event) => {
    if (!IS_OPENED) {
      // If the dropdown is not open, don't do anything.
      return;
    }

    const element = event?.target as HTMLElement;

    if (selectLabelElement && (selectLabelElement === element || selectLabelElement.contains(element))) {
      // If the click target is the select label element or one of its children,
      // don't hide the dropdown.
      return;
    }
    
    if (wrapperElement !== element && !wrapperElement?.contains(element) && document.contains(element)) {
      // If the click target is not the wrapper element or one of its children,
      // and it is still a part of the document, hide the dropdown.
      removeEventListener(document, events.mousedownOutsideDropdown);
      hideDropdown();
    }
  }, [IS_OPENED, wrapperElement, selectLabelElement, hideDropdown]);

  /**
   * Checks if the wrapper element's size or position has changed and updates
   * the dropdown position and settings if so.
   *
   * This function is called when the window is resized and when the dropdown
   * is first opened. It checks if the wrapper element's size or position has
   * changed since the last time the dropdown was repositioned. If it has,
   * it updates the dropdown position and settings.
   *
   * The "dropdownRepositionData" object is used to store the wrapper element's
   * size and position. It is used to compare the current size and position
   * of the wrapper element with its previous size and position. If the size
   * or position has changed, the dropdown position is updated.
   */
  const repositionDropdown = useCallback(() => {
    // If the wrapper element is not available, then the dropdown is not
    // currently visible, so there is no need to reposition it.
    if (!wrapperElement) {
      return;
    }

    // Get the current size and position of the wrapper element.
    const { width, height, top, left } = wrapperElement.getBoundingClientRect();

    // Get the previous size and position of the wrapper element.
    const {
      wrapperWidth,
      wrapperHeight,
      wrapperTop,
      wrapperLeft
    } = dropdownRepositionData;

    // Check if the size or position of the wrapper element has changed.
    if (
      width !== wrapperWidth
      || height !== wrapperHeight
      || top !== wrapperTop
      || left !== wrapperLeft
    ) {
      // If the size or position has changed, update the dropdown position.
      adjustDropdownPosition();

      // Update the "dropdownRepositionData" object with the new size and
      // position of the wrapper element.
      updateSettings((settings) => ({
        ...settings,
        dropdownRepositionData: {
          wrapperWidth: width,
          wrapperHeight: height,
          wrapperTop: top,
          wrapperLeft: left
        }
      }));
    }
  }, [wrapperElement, dropdownRepositionData, adjustDropdownPosition]);

  useEffect(() => {
    const eventKey = events.csDropdownReposition;

    if (hasEventListener(document, eventKey)) {
      removeEventListener(document, eventKey);
      addEventListener(document, eventKey, repositionDropdown);
    }
  }, [repositionDropdown]);

  /**
   * This function is called when the window is resized.
   * If the dropdown is open, it is hidden.
   * If the dropdown is closed, the dropdown is repositioned
   * to account for the new window size.
   */
  const onWindowResize = useCallback(() => {
    // If the dropdown is open, hide it.
    if (IS_OPENED) {
      hideDropdown();
    }
    else {
      // If the dropdown is closed, reposition it to account for the new window size.
      // The "forceReposition" parameter is set to true to force the repositioning
      // of the dropdown, even if the dropdown is closed.
      adjustDropdownPosition(true, true);
    }
  }, [IS_OPENED, adjustDropdownPosition, hideDropdown]);

  /**
   * This function is called when the user scrolls the page and the dropdown is open.
   * If the user is not scrolling the dropdown element or any of its children,
   * the dropdown is hidden.
   *
   * This behavior is useful when the user is scrolling the page and accidentally
   * opens the dropdown with the mouse wheel.
   *
   * @param {Event} event The event object representing the wheel event.
   */
  const onWheelHideDropdown = useCallback((event: Event) => {
    const element = event?.target as HTMLElement;

    if (
      IS_OPENED
      // And the element that triggered the event is not the dropdown element
      // or any of its children
      && dropdownScrollElement !== element
      && !dropdownScrollElement?.contains(element)
      // And the element is contained within the document
      && document.contains(element)
    ) {
      // Remove the event listener so that the dropdown isn't hidden again
      // if the user scrolls the page again.
      removeEventListener(window, events.wheelHideDropdown);
      // Hide the dropdown
      hideDropdown();
    }
  }, [IS_OPENED, dropdownScrollElement, hideDropdown]);

  useEffect(() => {
    const resizeEventKey = events.resizeDropdown;

    if (hasEventListener(window, resizeEventKey)) {
      removeEventListener(window, resizeEventKey);
      addEventListener(window, resizeEventKey, onWindowResize);
    }
  }, [onWindowResize]);
  
  useEffect(() => {
    const eventKey = events.mousedownOutsideDropdown;

    if (hasEventListener(document, eventKey)) {
      removeEventListener(document, eventKey);
      addEventListener(document, eventKey, onClickOutsideOptions);
    }
  }, [onClickOutsideOptions]);

  useEffect(() => {
    const eventKey = events.wheelHideDropdown;

    if (hasEventListener(window, eventKey)) {
      removeEventListener(window, eventKey);
      addEventListener(window, eventKey, onWheelHideDropdown);
    }
  }, [onWheelHideDropdown]);

  const onScrollAdjustDropdownPosition = useCallback((event: Event) => {
    if ((event.target as HTMLElement)?.contains(wrapperElement)) {
      adjustDropdownPosition(false, true);
    }
  }, [adjustDropdownPosition])

  useEffect(() => {
    const eventKey = events.scrollAdjustDropdownPosition;

    if (hasEventListener(window, eventKey)) {
      removeEventListener(window, eventKey);
      addEventListener(window, eventKey, onScrollAdjustDropdownPosition, true);
    }
  }, [onScrollAdjustDropdownPosition]);

  const jumpToSelectedOption = useCallback(() => {
    if (!dropdownScrollElement) {
      return;
    }

    const optionElements: Element[] = Array.from(
      dropdownScrollElement.children
    );

    const optionElement = optionElements.find((element) => element.querySelector('button')?.value === selected[0]);

    if (optionElement) {
      dropdownScrollElement.scrollTop = dropdownScrollElement.scrollHeight;
      setTimeout(() => jumpToOption(optionElement, "up"), 0);
    }
  }, [dropdownScrollElement, selected]);

  /**
   * Shows the dropdown when the select is clicked.
   * 
   * @param {boolean} jumpToSelectedOptionOnOpen Whether to jump to the selected option on open (default: true).
   */
  const showDropdown = (jumpToSelectedOptionOnOpen: boolean = true) => {
    if (IS_OPENED) {
      // Don't do anything if the dropdown is already open.
      return;
    }

    // Adjust the dropdown position based on the select element's position.
    adjustDropdownPosition(true);

    // If the dropdown needs to be repositioned because the DOM has changed,
    // add an event listener to observe the document body for changes.
    if (dropdownRepositionObserver) {
      addEventListener(document, events.csDropdownReposition, repositionDropdown);

      // Observe the document body for changes.
      dropdownRepositionObserver.observe(document.body, {
        // Watch for changes to the DOM tree.
        childList: true,
        // Watch for changes to the DOM tree below the document body.
        subtree: true,
        // Watch for changes to the attributes of elements in the DOM tree.
        attributes: true
      });
    };

    // Add event listeners to hide the dropdown when the user clicks outside of it
    // or when the window is resized.
    addEventListener(document, events.mousedownOutsideDropdown, onClickOutsideOptions);
    addEventListener(window, events.wheelHideDropdown, onWheelHideDropdown);
    addEventListener(window, events.scrollAdjustDropdownPosition, onScrollAdjustDropdownPosition, true);
    addEventListener(window, events.resizeDropdown, onWindowResize);

    // If the select is not multiple and there is a selected option, jump to it
    // on open.
    if (jumpToSelectedOptionOnOpen && selected.length && !multiple) {
      // Add an action to jump to the selected option on open.
      Actions?.add("onOpen", "jumpToSelectedOption", jumpToSelectedOption);
    }

    // Wait for the next tick to ensure that the state is updated before calling
    // the onOpen action.
    setTimeout(() => Actions?.do("onOpen"), 0);

    // Update the state to reflect that the dropdown is open.
    updateSettings((settings) => ({
      ...settings,
      openState: OpenState.OPENED,
      optionTabIndex: 0
    }));
  };

  /**
   * Handles click events on the select label.
   * 
   * The dropdown is opened when the select label element or one of its
   * children is clicked.
   * 
   * @param {MouseEvent | Event} event The click event.
   */
  const onSelectLabelClick = (event: MouseEvent | Event) => {
    const element = event?.target as HTMLElement;
    
    if (
      (selectLabelElement === element || selectLabelElement?.contains(element))
      && wrapperElement !== element
      && !wrapperElement?.contains(element)
    ) {
      showDropdown();
    }
  };

  useEffect(() => {
    const eventKey = events.clickShowSelectOptions;

    if (selectLabelElement && hasEventListener(selectLabelElement, eventKey)) {
      removeEventListener(selectLabelElement, eventKey);
      addEventListener(selectLabelElement, eventKey, onSelectLabelClick);
    }
  }, [selectLabelElement, onSelectLabelClick, showDropdown]);

  /**
   * Retrieves the list of options that are currently visible in the dropdown.
   * 
   * The function starts with all available options and then filters them
   * based on the current search criteria, if any. If a search is active, it
   * reduces the options to only those that match the search terms, applying
   * highlighted HTML for matched results. Finally, it filters out any options
   * without valid HTML content, ensuring only options with non-empty HTML are
   * included.
   * 
   * @returns {OptionType[]} An array of visible options, potentially
   * filtered and highlighted, based on the current search state.
   */
  const getVisibleOptions = (): OptionType[] => {
    // Start with all options as visible options
    let visibleOptions = [...options];

    if (isSearching) {
      // Reduce the options to only those that match the search criteria
      visibleOptions = visibleOptions.reduce((reducedOptions: OptionType[], option, index) => {
        const { isSearchMatch, searchMatchHighlightedHtml } = optionSearchConfig[index];

        // If the option is a search match, add it to the reduced options
        if (isSearchMatch) {
          reducedOptions.push({
            ...option,
            // Use the highlighted HTML for matched search results
            html: searchMatchHighlightedHtml
          });
        }

        return reducedOptions;
      }, []);
    }

    // Filter out options that do not have valid HTML content by ensuring it is
    // a non-empty string.
    return visibleOptions.filter(({ html }) => (
      html && (html as string).length
    ));
  };

  const getSelectedOptions = (): OptionType[] => {
    return options.filter(({ value }) => selected.includes(value));
  };

  const arrowNavigateOptions = (event: React.KeyboardEvent) => {
    const { key, shiftKey } = event;

    // Check if the key pressed is one of the navigation keys
    if (![Keys.ARROW_DOWN, Keys.ARROW_UP, Keys.TAB, Keys.ESCAPE].includes(key)) {
      return;
    }

    // Get the current visible options and check if the event target is the select handle
    const visibleOptions = getVisibleOptions();
    const isSelectHandleTarget = event.target === selectHandleElement;
    
    // Exit if there is no scroll element, dropdown is not open, or there are no options
    if (!dropdownScrollElement || (!IS_OPENED && !isSelectHandleTarget) || !visibleOptions.length) {
      return;
    }

    const isTab = key === Keys.TAB;

    // If shift+tab is pressed and target is the select handle, hide the dropdown
    if (isTab && shiftKey && isSelectHandleTarget) {
      hideDropdown();
      return;
    }

    let isArrowDown = key === Keys.ARROW_DOWN;
    let isArrowUp = key === Keys.ARROW_UP;

    // Convert the dropdown's child elements to an array
    const optionElements: Element[] = Array.from(dropdownScrollElement.children);

    // Initialize the new focused option as the currently focused option
    let newFocusedOption: Element | null | undefined = focusedOption;

    // If there is no new focused option and an element is active, find the active option
    if (!newFocusedOption && document.activeElement) {
      newFocusedOption = optionElements.find((element) => element === document.activeElement?.parentNode);
    }

    // Adjust arrow direction if Tab is pressed and an option is focused
    if (isTab && newFocusedOption) {
      if (shiftKey) {
        isArrowUp = true; // Shift+Tab should navigate upwards
      } else {
        isArrowDown = true; // Tab should navigate downwards
      }
    }

    if (isArrowDown) {
      const firstOption = optionElements[0];

      // If no option is focused, start with the first option
      if (!newFocusedOption) {
        newFocusedOption = firstOption;
      } else {
        // Otherwise, move focus to the next sibling option
        const nextOption = newFocusedOption.nextElementSibling as HTMLDivElement;

        if (nextOption) {
          newFocusedOption = nextOption;

          // Prevent default tab behavior if Tab key is used
          if (isTab) {
            event.preventDefault();
          }
        } else if (isTab) {
          // If Tab is pressed and no next option, hide the dropdown
          hideDropdown();
          return;
        } else {
          // If not using Tab, wrap around to the first option
          newFocusedOption = firstOption;
        }
      }
    } else if (isArrowUp) {
      const lastOption = optionElements.at(-1);

      // If no option is focused, start with the last option
      if (!newFocusedOption) {
        newFocusedOption = lastOption;
      } else {
        // Otherwise, move focus to the previous sibling option
        const prevOption = newFocusedOption.previousElementSibling as HTMLDivElement;

        if (prevOption) {
          newFocusedOption = prevOption;

          // Prevent default tab behavior if Tab key is used
          if (isTab) {
            event.preventDefault();
          }
        } else if (!isTab) {
          // If not using Tab, wrap around to the last option
          newFocusedOption = lastOption;
        }
      }
    } else if (key === Keys.ESCAPE) {
      // Hide dropdown and refocus the select handle element if Escape is pressed
      hideDropdown();
      selectHandleElement?.focus();
      return;
    }
    
    // If navigation occurred, jump to the new focused option
    if (isArrowDown || isArrowUp) {
      jumpToOption(newFocusedOption, isArrowDown ? "down" : "up", true);
    }

    // Update settings to reflect keyboard navigation and the newly focused option
    updateSettings((settings) => ({
      ...settings,
      isNavigatingWithKeyboard: true,
      focusedOption: newFocusedOption as HTMLDivElement
    }));
  };

  /**
   * Adjusts the scroll position of the dropdown scroll container to reveal the given option element, based on the specified direction.
   * If the option element is already visible, no scrolling occurs.
   * If the focus parameter is true, the button within the option element is focused without scrolling.
   * 
   * @param optionElement The option element to scroll to (or null to do nothing).
   * @param direction The direction to scroll to the option element. Either "down" or "up".
   * @param focus If true, the button within the option element is focused without scrolling.
   */
  const jumpToOption = (optionElement?: Element | null, direction: string = "down", focus: boolean = false) => {
    // Get the button element within the optionElement, if it exists
    const button = optionElement?.querySelector("button");
    
    // Check if the necessary elements exist, return early if any are missing
    if (!dropdownElement || !dropdownScrollElement || !optionElement || !button) {
      return;
    }

    // Retrieve all option elements within the dropdown scroll container
    const optionElements: Element[] = Array.from(dropdownScrollElement.children);

    // Initial scroll position of the dropdown scroll container
    let scrollTop = dropdownScrollElement.scrollTop;

    // Calculate padding top and row gap values from CSS
    const scrollPaddingTop = parseFloat(window.getComputedStyle(dropdownScrollElement).getPropertyValue("padding-top"));
    const rowGap = parseFloat(window.getComputedStyle(dropdownScrollElement).getPropertyValue("row-gap"));
    
    // Retrieve the Y-axis scale factor for accurate height calculations
    const { scaleY } = getCSSTransformMatrix(dropdownElement);
    
    // Calculate the visible height of the dropdown scroll container
    const dropdownScrollElementHeight = dropdownScrollElement.getBoundingClientRect().height / scaleY;
    
    // Determine the bottom limit of the visible scroll area
    const scrollViewVisibleAreaBottom = scrollTop + dropdownScrollElementHeight;
    
    // Find the index of the optionElement within the list of children
    const optionElementIndex = optionElements.indexOf(optionElement);
    
    // Calculate the height of the optionElement
    const optionElementHeight = optionElement.getBoundingClientRect().height / scaleY;

    // Calculate the top position of the optionElement, starting with row gap
    let optionElementTop = optionElementIndex * rowGap;

    // Accumulate the heights of all previous sibling elements
    for (const option of optionElements) {
      if (option === optionElement) {
        break;
      }

      optionElementTop += option.getBoundingClientRect().height / scaleY;
    }

    // Calculate the bottom position of the optionElement
    const optionElementBottom = optionElementTop + (scrollPaddingTop * 2) + optionElementHeight;
    
    // Check if the optionElement is fully visible in the current scroll view
    const isVisibleTop = optionElementTop >= scrollTop;
    const isVisibleBottom = optionElementBottom <= scrollViewVisibleAreaBottom;

    // Focus the button if requested, without scrolling
    if (focus) {
      button.focus({ preventScroll: true });
    }

    // Adjust the scroll position based on the direction and visibility
    if (direction === "down") {
      if (!optionElement.previousElementSibling) {
        // Scroll to the top if there's no previous sibling
        scrollTop = 0;
      }
      else if (!isVisibleBottom) {
        // Scroll down to reveal the option element
        scrollTop = optionElementTop
          - dropdownScrollElementHeight
          + optionElementHeight
          + scrollPaddingTop * 2;
      }
    }
    else if (!optionElement.nextElementSibling) {
      // Scroll to the bottom if there's no next sibling
      scrollTop = dropdownScrollElement.scrollHeight;
    }
    else if (!isVisibleTop) {
      // Scroll up to reveal the option element
      scrollTop = optionElementTop;
    }

    // Apply the calculated scroll position to the dropdown scroll container
    dropdownScrollElement.scrollTop = Math.round(scrollTop * 10) / 10;
  };

  /**
   * Handles the focus event on the select handle element.
   * 
   * This function adds a 'cs-focused' class to the select handle element to
   * visually indicate that it has focus. It also adjusts the position of the
   * dropdown to ensure that it is correctly aligned with the select handle.
   */
  const onSelectHandleFocus = () => {
    // Add the 'cs-focused' class to the select handle element if it exists
    selectHandleElement?.classList.add("cs-focused");

    // Adjust the position of the dropdown to align with the select handle
    adjustDropdownPosition();
  };

  /**
   * Handles the blur event on the select handle element.
   * 
   * This function removes the 'cs-focused' class from the select handle element
   * to visually indicate that it has lost focus.
   */
  const onSelectHandleBlur = () => {
    // Remove the 'cs-focused' class from the select handle element if it exists
    selectHandleElement?.classList.remove("cs-focused");
  };

  /**
   * Toggles the visibility of the dropdown menu. Opens the dropdown if it is 
   * currently closed, and closes it if it is open. If the event target contains 
   * 'cs-clear' or 'cs-reset' in its class list, the function will return early 
   * without toggling the dropdown state.
   *
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} [event] - Optional mouse event 
   * that triggered the toggle. Used to check the target element's class list.
   */
  const toggleDropdown = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // If an event is provided, check the target element's class list
    if (event && event?.target) {
      const classList = (event.target as HTMLElement).classList;
      
      // If the target element contains 'cs-clear' or 'cs-reset' in its class list,
      // return early without toggling the dropdown state
      if (classList.contains('cs-clear') || classList.contains('cs-reset')) {
        return;
      }
    }
    
    // If the dropdown is not currently open, show the dropdown
    if (!IS_OPENED) {
      showDropdown();
    }
    else {
      // If the dropdown is currently open, hide the dropdown
      hideDropdown();
    }
  };

  /**
   * Handles option selection.
   * 
   * @param {string} value The value of the selected option.
   * 
   * @returns {void}
   */
  const selectOption = (value: string) => {
    let updatedSelections: string[];
    
    if (multiple) {
      /**
       * If the select is configured to be multiple, this function will add the selected value to the 
       * array of selected values.
       */
      updatedSelections = arrayAddUnique(selected, value);
    }
    else {
      /**
       * If the select is not configured to be multiple, this function will simply set the selected value
       * to be the new value.
       */
      updatedSelections = [value];
    }

    let optionImageSrc: string | null | undefined;

    if (showSelectedOptionImage && !multiple) {
      /**
       * If the select is configured to show the selected option's image within the select handle, this 
       * function will find the selected option from the array of options and update the 
       * selectedOptionImageSrc in the settings with the selected option's image source.
       */
      const selectedOption = options.find(({ value: optionValue }) => value === optionValue);
      
      if (selectedOption) {
        optionImageSrc = selectedOption.selectedImageSrc;
      }
    }

    updateSettings((settings) => ({
      ...settings,
      selectedOptionImageSrc: optionImageSrc
    }));

    updateSelectedValues(updatedSelections);
  };

  /**
   * Removes the specified value from the list of selected values.
   * 
   * This function is used when the user clicks the remove button for a selected option.
   * 
   * @param {string} value The value of the selected option to be removed.
   * 
   * @returns {void}
   */
  const removeSelectedOption = (value: string) => {
    // Filter the current selected values to remove the specified value
    const updatedSelections = selected.filter((selectedValue) => selectedValue !== value);

    // Update the select settings, specifically setting the selectedOptionImageSrc to null
    // This indicates that there is no image associated with the currently selected option
    updateSettings((settings) => ({
      ...settings,
      selectedOptionImageSrc: null
    }));

    // Update the selected values in the component's state with the filtered selections
    updateSelectedValues(updatedSelections);
  };

  const contextValue: SelectContextType = {
    settings,
    updateSettings,
    showDropdown,
    hideDropdown,
    toggleDropdown,
    adjustDropdownPosition,
    arrowNavigateOptions,
    getVisibleOptions,
    getSelectedOptions,
    resetSearch,
    showSearchInputWrapShadow,
    preventScrollOnArrowPress,
    selectOption,
    removeSelectedOption,
    updateSelectedValues,
    onSelectKeyDown,
    IS_OPENED,
    IS_CLOSING,
    IS_CLOSED
  };

  return (
    <SelectSettingsContext.Provider value={contextValue}>
      <div {...wrapperAttrs} ref={wrapperRef}>
        {init && (
          <>
            {!usesExistingSelectElement && <SelectElement onSelectChange={onSelectChange}/>}
            <div
              ref={selectHandleRef}
              className="cs-handle"
              onClick={toggleDropdown}
              onKeyDown={onSelectKeyDown}
              onKeyUp={preventScrollOnArrowPress}
              onFocus={onSelectHandleFocus}
              onBlur={onSelectHandleBlur}
              tabIndex={0}
            >
              {(!!icon || showSelectedOptionImage) && <SelectedOptionImages/>}
              {showSelection && placeholder.length > 0 && (
                <div className="cs-placeholder">
                  <span>{placeholder}</span>
                </div>
              )}
              {showSelection && <SelectedOptions/>}
              {clearable && <ResetButton/>}
              {showArrow && (
                <button type="button" className="cs-arrow" tabIndex={-1}>
                  <IconChevronDown/>
                </button>
              )}
            </div>
            <Options/>
          </>
        )}
      </div>
    </SelectSettingsContext.Provider>
  );
}
