import React, { useContext, useEffect, useRef, useCallback } from "react";
import { arrayAddUnique, arrayWithout, addEventListener, removeEventListener } from "../../helpers";
import SearchInput from "./SearchInput";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";
import IconCheck from "../icons/IconCheckmark";
import { SelectSettingsType } from "./types";

export default function Options(): React.JSX.Element {
  const {
    settings,
    updateSettings,
    hideDropdown,
    adjustDropdownPosition,
    getVisibleOptions,
    preventScrollOnArrowPress,
    showSearchInputWrapShadow,
    onSelectKeyDown,
    selectOption,
    removeSelectedOption,
    IS_OPENED
  } = useContext(SelectSettingsContext);
  
  const {
    selected,
    multiple,
    searchable,
    isSearching,
    selectHandleElement,
    dropdownClassList,
    focusedOption,
    options,
    showSelectedOptionImage,
    selectedOptionImageSrc,
    searchMatchCount,
    noOptionsText,
    noResultsText
  } = settings;

  const { dropdownStyle } = settings;
  const dropdownRef = useRef(null);
  const dropdownScrollRef = useRef(null);

  useEffect(() => {
    updateSettings((settings): SelectSettingsType => ({
      ...settings,
      dropdownElement: dropdownRef?.current,
      dropdownScrollElement: dropdownScrollRef?.current
    }));
  }, []);

  const toggleSelectOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;

    if (multiple) {
      if (!selected?.includes(value)) {
        selectOption(value);
      }
      else {
        removeSelectedOption(value);
      }

      setTimeout(adjustDropdownPosition, 0);
    }
    else {
      selectOption(value);
      hideDropdown();
      selectHandleElement?.focus();
    }
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = event.currentTarget;
    const { dropdownClassList } = settings;

    if (scrollTop > 0 && dropdownClassList.includes("-scrolled")) {
      return;
    }

    showSearchInputWrapShadow(scrollTop > 0);
  }

  const onOptionMouseDown = () => {
    updateSettings((settings) => ({
      ...settings,
      isNavigatingWithKeyboard: false
    }));
  }

  const onOptionKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    preventScrollOnArrowPress(event);
    onSelectKeyDown(event);
  }

  const onDropdownMouseOver = () => {
    if (!IS_OPENED) {
      adjustDropdownPosition();
    }
  }

  const onOptionFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    const parentElement = event.currentTarget.parentNode as HTMLDivElement;

    if (focusedOption !== parentElement) {
      updateSettings((settings) => ({
        ...settings,
        focusedOption: parentElement
      }));
    }
  };

  const onOptionBlur = (event: React.FocusEvent<HTMLButtonElement>) => {
    updateSettings((settings) => ({
      ...settings,
      focusedOption: null
    }));
  };

  const visibleOptions = getVisibleOptions();
  const scrollableClasses = ["cs-scrollable"];

  if (isSearching && searchMatchCount === 0) {
    scrollableClasses.push("-no-results");
  }

  return (
    <dialog
      ref={dropdownRef}
      className={dropdownClassList.join(" ")}
      style={dropdownStyle}
      onKeyDown={(event) => preventScrollOnArrowPress(event)}
      onKeyUp={(event) => preventScrollOnArrowPress(event)}
      onMouseOver={() => onDropdownMouseOver()}
      open={IS_OPENED}
    >
      {options.length && searchable && <SearchInput/>}
      
      <div className="cs-options">
        <div
          ref={dropdownScrollRef}
          className="cs-scrollable"
          onScroll={(event) => onScroll(event)}
        >
          {visibleOptions.map((data, index) => {
            const { value, html } = data;
            const classes = ["cs-option"];
            
            if (selected.includes(value)) {
              classes.push("-selected");
            }
            
            return (
              <div className={classes.join(" ")} key={`option-${index}`}>
                <button
                  type="button"
                  value={value}
                  tabIndex={IS_OPENED ? 0 : -1}
                  dangerouslySetInnerHTML={{ __html: html as string }}
                  onClick={toggleSelectOption}
                  onKeyDown={onOptionKeyDown}
                  onKeyUp={preventScrollOnArrowPress}
                  onMouseDown={onOptionMouseDown}
                  onFocus={onOptionFocus}
                  onBlur={onOptionBlur}
                />
                {multiple && (
                  <span className="cs-option-checkbox">
                    <IconCheck/>
                  </span>
                )}
              </div>
            );
          })}

          {!options.length && (
            <div
              className="cs-no-options"
              dangerouslySetInnerHTML={{ __html: noOptionsText }}
            />
          )}

          {options.length && isSearching && searchMatchCount === 0 && (
            <div
              className="cs-no-results"
              dangerouslySetInnerHTML={{ __html: noResultsText }}
            />
          )}
        </div>
      </div>
    </dialog>
  );
}